import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios, {all} from 'axios';
import dayjs from 'dayjs';
import {orderBy} from 'lodash';
import timezone from 'dayjs/plugin/timezone';
import dayjsBusinessDays from 'dayjs-business-days';
import ReportsContext from '../Context/ReportsContext';
import Report from '../Components/Reports/Report';

dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');
dayjs.extend(dayjsBusinessDays);

// const url = 'http://localhost';
const Reports = ({currentDate, scannerMeta, theme, scannerConfig}) => {
  // const currentDate = '2024-11-03';
  const url = process.env.REACT_APP_REPORT_URL ?? 'http://localhost';
  const params = useParams();
  const [report, setReport] = useState(null);
  const [allReports, setAllReports] = useState(null);
  const [symbols, setSymbols] = useState([]);
  const [reportSettings, setReportSettings] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedScanner, setSelectedScanner] = useState(null);
  const [symbolData, setSymbolData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingAllReports, setLoadingAllReports] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [selectableScanners, setSelectableScanners] = useState(null);
  const [scannersMeta, setScannersMeta] = useState(null);
  const [reportFilters, setReportFilters] = useState([]);
  const [activeReportFilters, setActiveReportFilters] = useState([]);
  const [viewingDate, setViewingDate] = useState(null);
  const [allScannersLocked, setAllScannersLocked] = useState(false);
  const [missingAgreement, setMissingAgreement] = useState(false);

  /**
   * GET to /get-symbols if input value else to /get-all-symbols
   * @param {String || null} inputValue
   * @param {*} retries
   * @returns
   */
  const fetchSymbols = async (inputValue, retries = 3, retry = false) => {
    if (loading && !retry) {
      return null;
    }
    if (!retry) {
      setLoading(true);
    }

    try {
      let request = '';
      if (inputValue) {
        request = `/get-symbols`;
      } else {
        request = `/get-all-symbols`;
      }
      const reqFormat = {
        method: 'get',
        url: request,
        baseURL: `${url}/api/reports`,
        timeout: 8000,
        params: {
          // group: encodeURIComponent(selectedScanner?.value),
          // type: selectedScanner?.type,
          group: encodeURIComponent(selectedReport?.value),
          type: 'report',
          token: localStorage.getItem('scanner-sso'),
          date: viewingDate,
        },
      };
      if (inputValue) {
        reqFormat.params.symbol = encodeURIComponent(`e${inputValue}`);
      }
      const values = await axios.request(reqFormat);
      if (values?.data?.dataset?.data?.length) {
        // Filter for unique symbols
        const symbolOptions = values?.data?.dataset?.data
          .filter(
            (s, i, arr) =>
              s?.formattedSymbol?.value &&
              arr.findIndex((a) => a?.formattedSymbol?.value === s?.formattedSymbol?.value) === i,
          )
          .map((s) => ({value: s?.Symbol?.value, label: s?.formattedSymbol?.value ?? s?.Symbol?.value}));
        const sorted = orderBy(symbolOptions, ['label'], ['asc']);
        setSymbols(sorted);
        if (!selectedSymbol && sorted?.length) {
          setSelectedSymbol(sorted[0]);
        }
        setLoading(false);

        return symbolOptions;
      }
      setSymbols([]);
      setLoading(false);

      return [];
    } catch (e) {
      console.error('[fetchSymbols] error', e);
      // retry
      if (retries > 0) {
        fetchSymbols(inputValue, retries - 1);
        return [];
      }
      setLoading(false);
      return [];
    }
  };

  /**
   * GET to /get-report
   * @param {String} reportName
   * @param {*} retries
   * @returns
   */
  const fetchReport = async (reportName, retries = 3, retry = false) => {
    if (loading && !retry) {
      return;
    }
    if (!retry) {
      setLoading(true);
    }
    try {
      const res = await axios.request({
        method: 'get',
        url: `/get-report`,
        baseURL: `${url}/api/reports`,
        timeout: 8000,
        params: {
          group: encodeURIComponent(reportName),
          token: localStorage.getItem('scanner-sso'),
          date: viewingDate,
        },
      });
      if (res?.data?.dataset) {
        setReport(res?.data?.dataset);
        setLoading(false);
      }
    } catch (e) {
      console.error('[fetchReport] error', e);
      if (retries > 0) {
        fetchReport(reportName, retries - 1, true);
        return;
      }
      setLoading(false);
    }
  };

  /**
   * GET to /get-symbol-data
   * @param {String} group
   * @param {String} symbol
   * @param {*} retries
   * @returns
   */
  const fetchSymbolData = async (group, symbol, retries = 3, retry = false) => {
    if (loading && !retry) {
      return;
    }
    if (!retry) {
      setLoading(true);
    }
    try {
      const res = await axios.request({
        method: 'get',
        url: `/get-symbol-data`,
        baseURL: `${url}/api/reports`,
        timeout: 8000,
        params: {
          group: encodeURIComponent(group),
          symbol: encodeURIComponent(symbol),
          token: localStorage.getItem('scanner-sso'),
          scannerGroup: selectedScanner?.value,
          type: selectedScanner?.type,
          date: viewingDate,
        },
      });
      if (res?.data?.dataset) {
        const {riskRewards, positionsBySymbol} = res?.data?.dataset;
        setSymbolData({riskRewards: riskRewards ?? {}, positionsBySymbol: positionsBySymbol ?? {}});
        setReportSettings(riskRewards?.settings ?? {});
      } else {
        setSymbolData({});
      }

      // Date Format: Day Month Year, HH:mm:ss am/pm
      setLastUpdated({symbol, datetime: dayjs().tz('America/New_York').format('MMM D YYYY, hh:mm:ss a')});
      setLoading(false);
    } catch (e) {
      console.error('[fetchSymbolData] error', e);
      // retry
      if (retries > 0) {
        fetchSymbolData(group, symbol, retries - 1, true);
        return;
      }
      setLoading(false);
    }
  };

  /**
   * GET to /get-reports
   * @param {*} retries
   * @returns
   */
  const fetchAllReports = async (retries = 3) => {
    try {
      const res = await axios.request({
        method: 'get',
        url: `/get-reports`,
        baseURL: `${url}/api/reports`,
        timeout: 8000,
        params: {
          token: localStorage.getItem('scanner-sso'),
          date: viewingDate,
        },
      });
      if (res?.data?.dataset) {
        setAllReports(res?.data?.dataset);
      }
      setLoadingAllReports(false);
    } catch (e) {
      console.error('[fetchAllReports] error', e);
      if (retries > 0) {
        fetchAllReports(retries - 1);
      }
      setLoadingAllReports(false);
    }
  };

  const resetState = () => {
    setSelectedSymbol(null);
    setSymbols([]);
    setReport(null);
    setSymbolData(null);
    setReportSettings(null);
    setActiveReportFilters([]);
  };

  const refreshSymbolData = () => {
    fetchSymbolData(selectedReport?.value, selectedSymbol?.value);
  };

  const updateSelectedReport = (e) => {
    resetState();
    const {value} = e;
    if (!scannersMeta) {
      return;
    }
    const matchingScanner = scannersMeta?.find((s) => s?.group === value);
    if (!matchingScanner) {
      return;
    }
    if (!matchingScanner || !matchingScanner?.report) {
      return;
    }
    const {riskReward} = matchingScanner?.report;
    const {groups} = scannerMeta;
    const matchingReport = groups.find((g) => g.group === riskReward);
    if (!matchingReport) {
      return;
    }
    setSelectedScanner(e);
    setSelectedReport({value: matchingReport?.group, label: matchingReport?.fullTitle});
  };

  const updateSelectedFilter = (filter, value, label) => {
    const filterType = reportFilters.find((f) => f.label === filter)?.filterType;
    setActiveReportFilters((prevState) => {
      const state = {...prevState};
      state[filter] = {
        ...state[filter],
        selectedValue: {value, label},
        filterType,
      };
      return state;
    });

    // setReportFilters(updatedFilters);
  };

  useEffect(() => {
    if (loadingAllReports) {
      return;
    }
    setLoadingAllReports(true);
    fetchAllReports();
  }, []);

  useEffect(() => {
    if (selectedReport && selectedReport?.label !== report?.fullTitle) {
      resetState();
      fetchReport(selectedReport?.value);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (Object.keys(scannerMeta ?? {})?.length) {
      const {allowedReports, groups, reportFilters: backendReportFilters, userRole} = scannerMeta;
      if (!allowedReports?.length) {
        return;
      }
      const scanners = groups.filter((g) => !g.hideTab).sort((a, b) => a.title.localeCompare(b.title));
      const uniqueScanners = scanners.filter(
        (s, i, arr) =>
          arr.findIndex((a) => {
            const aGroup = a?.group?.includes('+Default')
              ? a?.group.split('+Default')[0]
              : a?.group?.includes('+Over6')
              ? a?.group.split('+Over6')[0]
              : a?.group;
            const sGroup = s?.group?.includes('+Default')
              ? s?.group.split('+Default')[0]
              : s?.group.includes('+Over6')
              ? s?.group.split('+Over6')[0]
              : s?.group;
            if (aGroup === sGroup) {
              return true;
            }
            return a.group === s.group;
          }) === i,
      );
      if (!uniqueScanners?.length) {
        return;
      }
      if (backendReportFilters?.length) {
        const activeReportFilt = backendReportFilters.reduce(
          (acc, f) => ({
            ...acc,
            [f.label]: {...f, selectedValue: {value: f.values[0].value, label: f.values[0].label}},
          }),
          {},
        );
        setActiveReportFilters(activeReportFilt);
        setReportFilters(backendReportFilters);
      }
      setScannersMeta(scanners);
      let formattedForSelect = uniqueScanners
        .map((g) => ({value: g.group, label: g.title, type: g.type}))
        .sort((a, b) => a.label.localeCompare(b.label));
      if (scannerMeta?.useSubScanners) {
        // formattedForSelect = formattedForSelect.filter((g) => scannerMeta?.subScanners?.includes(g.title))
        const allowedRoles = ['admin', 'customer', 'read-only admin'];
        if (scannerMeta?.subscanners?.length) {
          if (!scannerMeta?.liveAccess) {
            setMissingAgreement(true);
            return;
          }
          console.log('scannerMeta?.subscanners', scannerMeta?.subscanners);
          const onlyOver6 = scannerMeta?.subscanners?.filter((subsc) => subsc?.live && subsc?.name?.includes('PRS'));
          formattedForSelect = formattedForSelect.filter((g) =>
            allowedRoles.includes(userRole)
              ? true
              : onlyOver6?.find((subsc) => subsc?.name?.replace(' PRS', '') === g.label),
          );
          console.log('onlyOver6', onlyOver6);

          if (!formattedForSelect?.length) {
            setAllScannersLocked(true);
            return;
          }
        } else if (!allowedRoles.includes(userRole)) {
          setAllScannersLocked(true);
          return;
        }
      }
      setSelectableScanners(formattedForSelect);
      setSelectedScanner(formattedForSelect[0]);
      const reportGroupsAllowed = groups
        .filter((g) => allowedReports.includes(g.group))
        .filter((g) => {
          if (scannerMeta?.useSubScanners) {
            return formattedForSelect?.find((subsc) => subsc?.label === g.mainScanner);
          }
          return true;
        });
      if (!reportGroupsAllowed?.length) {
        return;
      }
      const firstScanner = scanners.find((s) => s.group === formattedForSelect[0]?.value);
      if (!firstScanner || !firstScanner?.report) {
        return;
      }
      const {riskReward} = firstScanner?.report;
      if (!allowedReports.includes(riskReward)) {
        return;
      }
      const matchingReport = groups.find((g) => g.group === riskReward);
      if (!matchingReport) {
        return;
      }

      setSelectedReport({value: matchingReport?.group, label: matchingReport?.fullTitle});
      fetchReport(matchingReport?.group);
    }
  }, [scannerMeta]);

  useEffect(() => {
    if (report && selectedReport?.label === report?.fullTitle) {
      fetchSymbols();
    }
  }, [report, selectedReport]);

  useEffect(() => {
    if (selectedSymbol && selectedReport?.label === report?.fullTitle) {
      const symbol = selectedSymbol?.value;
      fetchSymbolData(selectedReport?.value, symbol);
    }
  }, [selectedSymbol, report, selectedReport]);

  useEffect(() => {
    // If currentDate is an american busness day, set viewingDate to currentDate
    // If currentDate is a weekend, set it to the previous Friday
    // If currentDate is an american holiday, set it to the previous business day
    let date = currentDate;
    if (!date) {
      date = dayjs().format('YYYY-MM-DD');
    }
    if (date) {
      const currentDateDay = dayjs(date);
      if (currentDateDay.isBusinessDay()) {
        setViewingDate(currentDateDay.format('YYYY-MM-DD'));
        return;
      }
      const dayOfWeek = currentDateDay.day();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday

      if (isWeekend) {
        let newDate = currentDateDay.subtract(1, 'day');
        while (!newDate.isBusinessDay()) {
          newDate = newDate.subtract(1, 'day');
        }
        setViewingDate(newDate.format('YYYY-MM-DD'));
      }
    }
  }, [currentDate]);

  const contextValue = useMemo(
    () => ({
      report,
      symbols,
      selectedSymbol,
      setSelectedSymbol,
      symbolData,
      reportSettings,
      theme,
      allReports,
      loadingAllReports,
      refreshSymbolData,
      lastUpdated,
      selectableScanners,
      selectedScanner,
      setSelectedScanner,
      updateSelectedReport,
      reportFilters,
      activeReportFilters,
      updateSelectedFilter,
      viewingDate,
    }),
    [
      report,
      symbols,
      selectedSymbol,
      setSelectedSymbol,
      symbolData,
      reportSettings,
      theme,
      allReports,
      loadingAllReports,
      refreshSymbolData,
      lastUpdated,
      selectableScanners,
      selectedScanner,
      setSelectedScanner,
      updateSelectedReport,
      reportFilters,
      activeReportFilters,
      updateSelectedFilter,
      viewingDate,
      selectedReport,
    ],
  );

  if (missingAgreement) {
    return (
      <div className="w-full h-full flex items-center justify-center flex-col gap-4">
        <h1>Reports are not available</h1>
        <h3>Please complete exchange data agreements before accessing this scanner.</h3>
      </div>
    );
  }

  if (allScannersLocked) {
    return (
      <div className="w-full h-full flex items-center justify-center flex-col gap-4">
        <h1>All Scanners are locked</h1>
        {/* <h3>If you want to gain access to this scanner, click here to access or call at this number <a href="tel:3122615581">(312)261-5581</a></h3> */}
        <h3>
        If you want to gain access to this scanner, call at <a href="tel:3122615581" style={{textDecoration: 'underline', color: 'rgb(65, 226, 65)'}}> (312) 261-5581</a>
        </h3>
      </div>
    );
  }

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      <ReportsContext.Provider value={contextValue}>
        <Report />
      </ReportsContext.Provider>
    </div>
  );
};

export default Reports;
