/**
 * URLs for live and delayed scanners.
 */
const adminUsersNeedAgreement = false;
const liveURL = window.liveURL ?? process.env.REACT_APP_LIVE_URL ?? 'https://as-live.f2-tech.com/scanners';
const delayedURL = window.delayedURL ?? process.env.REACT_APP_DELAYED_URL ?? 'https://as-dlyd.f2-tech.com/scanners';
const allowedRoles = ['admin', 'customer', 'read-only admin'];
const showDelayedInSidebarWhenLiveAccess = false;

const setupSubScannersForAdmins = (arr, scannerData, isLive, token, liveAccess) =>
  arr
    .reduce((acc, scanner) => {
      const groupName = scanner?.title;
      if (!groupName || !scannerData?.slug) return acc;
      const delayedRedirect = `${delayedURL}/${scannerData?.slug}?group=${encodeURIComponent(
        scanner.title,
      )}&delayed=true&token=${token}`;
      const liveRedirect = `${liveURL}/${scannerData?.slug}?group=${encodeURIComponent(
        scanner.title,
      )}&delayed=false&token=${token}`;
      const isOver6 = scanner?.title?.includes('Over6');
      const visibleName = isOver6 ? scanner?.title.replace('Over6', 'PRS') : scanner?.title;

      const delayedName = `${visibleName} Delayed`;
      if (isOver6) {
        return [
          ...acc,
          {
            ...scanner,
            visibleName,
            locked: false,
            live: true,
            redirect: !isLive ? liveRedirect : null,
            agreementRequired: adminUsersNeedAgreement ? !liveAccess : false,
          },
        ];
      }
      const delayedTab = {
        ...scanner,
        visibleName: delayedName,
        locked: false,
        live: false,
        redirect: isLive ? delayedRedirect : null,
        agreementRequired: false,
      };
      const liveTab = {
        ...scanner,
        visibleName,
        locked: false,
        live: true,
        redirect: !isLive ? liveRedirect : null,
        agreementRequired: adminUsersNeedAgreement ? !liveAccess : false,
      };
      return [...acc, delayedTab, liveTab];
    }, [])
    .sort((a, b) => a.title.localeCompare(b.title));

const setupSubScannersForMembers = (arr, scannerData, isLive, token, liveAccess) => {
  let subscanners = scannerData?.subscanners;
  if (!showDelayedInSidebarWhenLiveAccess) {
    subscanners = scannerData?.subscanners.reduce((acc, s) => {
      const multipleOfSameScanner = scannerData?.subscanners?.filter((ss) => ss?.name === s?.name);
      if (multipleOfSameScanner?.length > 1) {
        const hasDelayedAndLive =
          !!multipleOfSameScanner.find((ss) => ss?.live) && !!multipleOfSameScanner.find((ss) => !ss?.live);
        if (hasDelayedAndLive) {
          if (s?.live) {
            return [...acc, s];
          }
          return acc;
        }
      }
      return [...acc, s];
    }, []);
  }
  return arr
    .reduce((acc, scanner) => {
      const groupName = scanner?.title;
      if (!groupName || !scannerData?.slug) return acc;
      let subScanner = null;
      let hasDelayedAndLive = false;

      if (showDelayedInSidebarWhenLiveAccess) {
        const matchingSubScanners =
          scannerData?.subscanners.filter((s) => {
            if (s?.name?.includes('PRS')) {
              const scTitle = scanner?.title.replace('Over6', 'PRS');
              return s.name === scTitle;
            }
            return s.name === groupName;
          }) ?? [];
        if (matchingSubScanners?.length) {
          if (matchingSubScanners?.length === 1) {
            hasDelayedAndLive = false;
          }
          if (matchingSubScanners?.length > 1) {
            hasDelayedAndLive = !!matchingSubScanners.find((s) => s?.live) && !!matchingSubScanners.find((s) => !s?.live);
          }
          if (hasDelayedAndLive) {
            subScanner = isLive ? matchingSubScanners.find((s) => s?.live) : matchingSubScanners.find((s) => !s?.live);
          } else {
            [subScanner] = matchingSubScanners;
          }
        }
      } else {
        subScanner =
          subscanners.find((s) => {
            if (s?.name?.includes('PRS')) {
              const scTitle = scanner?.title.replace('Over6', 'PRS');
              return s.name === scTitle;
            }
            return s.name === groupName;
          }) ?? false;
      }

      const isOver6 = scanner?.title?.includes('Over6');
      const visibleName = isOver6 ? scanner?.title.replace('Over6', 'PRS') : scanner?.title;
      const delayedName = `${visibleName} Delayed`;
      // If user does not have access to the subscanner
      if (!subScanner) {
        if (isOver6) {
          return [
            ...acc,
            {
              ...scanner,
              visibleName,
              locked: true,
            },
          ];
        }
        return [
          ...acc,
          {
            ...scanner,
            visibleName: delayedName,
            locked: true,
          },
          {
            ...scanner,
            visibleName,
            locked: true,
          },
        ];
      }

      const delayedRedirect = `${delayedURL}/${scannerData?.slug}?group=${encodeURIComponent(
        scanner.title,
      )}&delayed=true&token=${token}`;
      const liveRedirect = `${liveURL}/${scannerData?.slug}?group=${encodeURIComponent(
        scanner.title,
      )}&delayed=false&token=${token}`;
      const userHasAccess = !!subScanner;

      if (isOver6) {
        return [
          ...acc,
          {
            ...scanner,
            visibleName,
            locked: !userHasAccess,
            agreementRequired: !liveAccess,
            live: true,
            redirect: !isLive && userHasAccess ? liveRedirect : null,
          },
        ];
      }
      
      const liveTab = {
        ...scanner,
        visibleName,
        locked: !(userHasAccess && (hasDelayedAndLive || subScanner?.live)),
        agreementRequired: hasDelayedAndLive || subScanner?.live ? !liveAccess : false,
        live: true,
        redirect: !isLive && userHasAccess ? liveRedirect : null,
      };
      const delayedTab = {
        ...scanner,
        visibleName: delayedName,
        locked: !userHasAccess,
        live: false,
        agreementRequired: false,
        redirect: isLive ? delayedRedirect : null,
      };

      if (userHasAccess) {
        if (showDelayedInSidebarWhenLiveAccess && hasDelayedAndLive) {
          return [...acc, delayedTab, liveTab];
        }
        if (subScanner?.live) {
          return [...acc, liveTab];
        }
      }

      return [...acc, delayedTab, liveTab];
    }, [])
    .sort((a, b) => a.title.replace(' Delayed', '').localeCompare(b.title.replace(' Delayed', '')))
    .sort((a, b) => a?.locked - b?.locked);
};

const setupSubScannersForNoAccess = (arr, scannerData) => {
  const formattedForSidebar = arr
    .reduce((acc, scanner) => {
      const groupName = scanner?.title;
      if (!groupName || !scannerData?.slug) return acc;
      const delayedName = `${scanner?.title} Delayed`;
      const isOver6 = scanner?.title?.includes('Over6');
      const over6Title = isOver6 ? scanner?.title.replace('Over6', 'PRS') : scanner?.title;

      if (isOver6) {
        return [
          ...acc,
          {
            ...scanner,
            visibleName: over6Title,
            locked: true,
            live: true,
            redirect: null,
          },
        ];
      }
      const delayedTab = {
        ...scanner,
        visibleName: delayedName,
        locked: true,
        live: false,
        redirect: null,
      };
      const liveTab = {
        ...scanner,
        visibleName: scanner?.title,
        locked: true,
        live: true,
        redirect: null,
      };
      return [...acc, delayedTab, liveTab];
    }, [])
    .sort((a, b) => a.title.replace(' Delayed', '').localeCompare(b.title.replace(' Delayed', '')));
  formattedForSidebar.push({
    group: 'noscanners',
    type: 'no-tabs-available',
    visibleName: 'No Scanners Available',
    title: 'No Scanners Available',
    locked: false,
  });
  return formattedForSidebar;
};

const setupSubScanners = (scannerData, groups, isLive, userData) => {
  const filtered = groups.filter((g) => g.type !== 'report' && !g?.hideTab);
  const {liveAccess} = scannerData ?? {};
  const url = new URL(window.location.href);
  let token = url.searchParams.get('token');
  if (token) {
    localStorage.setItem('scanner-sso', token);
  } else {
    token = localStorage.getItem('scanner-sso');
  }

  if (!scannerData?.useSubScanners) {
    // If scanner is not using subscanners
    return filtered.map((scanner) => ({
      ...scanner,
      locked: false,
      redirect: null,
    }));
  }

  // If user has admin, customer, or read-only admin role
  if (allowedRoles.includes(userData?.role)) {
    return setupSubScannersForAdmins(filtered, scannerData, isLive, token, liveAccess);
  }

  // If user has subscanner array
  if (scannerData?.subscanners?.length) {
    const scanners = setupSubScannersForMembers(filtered, scannerData, isLive, token, liveAccess);
    scanners.push({
      group: 'noscanners',
      type: 'no-tabs-available',
      visibleName: 'No Scanners Available',
      title: 'No Scanners Available',
      locked: false,
    });

    return scanners;
  }
  // If user does not have a subscanner array
  return setupSubScannersForNoAccess(filtered, scannerData);
};
export default setupSubScanners;
