import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import ScannerTable from './ScannerTable';
import Header from '../Header/Header';
import 'react-datepicker/dist/react-datepicker.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const Scanner = ({
  group,
  groupNumber,
  multiScreen,
  dispatch,
  hasChild,
  openOptionsModal,
  showReportModal,
  openReportModal,
  closeReportModal,
  hasReportPopout,
  optionsColumns,
  optionsAllowed,
  getSymbolData,
  wsSocket,
  openLockedModal,
  groupCollection,
  scannerLayout,
  groups,
  hideSearch,
  hideHeader,
  allowExportCSV,
  useSecondaryHeaderTitle,
  hidePagination,
  openModal,
  hideDateSelectorForGroups,
  alignColumns,
  trimNumbersFromSymbol = false,
  hasMainReport,
  reports,
  updatingDate,
  setUpdatingDate,
  currentTab,
  setCurrentTab,
  currentDate,
  inView,
  colorLegend,
  openLegendModal,
  lockedColumns,
  customLockedColumns,
}) => {
  const [symbolsWithReports, setSymbolsWithReports] = useState([]);
  // const [reportForGroup, setReportForGroup] = useState(null);

  // const matchingSymbols = (reportName, isStats = false) => {
  //   const matchingReport = reports?.[reportName] ?? null;
  //   if (!matchingReport) {
  //     return [];
  //   }
  //   const {rawData} = matchingReport;
  //   if (!rawData?.length) {
  //     return [];
  //   }
  //   const symbolsOnly = rawData
  //     .map((r) => {
  //       const symbol = r?.Symbol?.value ?? null;
  //       if (isStats) {
  //         return r?.Stats?.length;
  //       }
  //       if (!symbol) {
  //         return null;
  //       }
  //       if (symbol?.charAt(0) === 'e') {
  //         // Remove the 'e' from the symbol.
  //         return symbol.slice(1);
  //       }
  //       return symbol;
  //     })
  //     .filter((s) => s);

  //   return symbolsOnly;
  // };

  // useEffect(() => {
  //   if (!Object.keys(reports ?? {})?.length || !group?.report?.report) {
  //     return;
  //   }
  //   // const matchingRiskRewardReport = group?.report?.riskReward ?? null;
  //   const matchingReport = group?.report?.report ?? null;

  //   let symbolsOnly = [];
  //   // if (matchingRiskRewardReport) {
  //   //   const riskRewardSymbols = matchingSymbols(matchingRiskRewardReport);
  //   //   if (riskRewardSymbols?.length) {
  //   //     symbolsOnly.push(...riskRewardSymbols);
  //   //   }
  //   // }
  //   if (matchingReport) {
  //     const reportSymbols = matchingSymbols(matchingReport);
  //     if (reportSymbols?.length) {
  //       symbolsOnly.push(...reportSymbols);
  //     }
  //   }
  //   // Filter For Unique Symbols
  //   symbolsOnly = [...new Set(symbolsOnly)];
  //   setSymbolsWithReports(symbolsOnly);
  //   // setReportForGroup(matchingReport);
  // }, [reports, inView, group]);

  const handleChangePage = (event, newPage) => {
    if (!group?.group) return;
    dispatch({type: 'SET_PAGE', payload: {group, page: newPage}});
  };

  const displaySecondaryTitle = () => {
    if (!group?.group?.includes('MP_DOT')) return false;
    // if (!group?.group?.includes('MP_DOT') && !group?.group?.includes('ShadowSignalScanner')) return false;
    const groupTitle = group?.group?.includes('+') ? group?.group?.split('+') : group?.group?.split('_');
    if (!groupTitle?.length || groupTitle?.length <= 1) {
      return '';
    }
    if (!groupTitle[1]) {
      return '';
    }
    if (groupTitle[1] === 'Main' || groupTitle[1] === 'Secondary') {
      return '';
    }
    return groupTitle[1];
  };
  return (
    <div className={`${hasChild ? 'hasChild pb-7' : ''} scanner-wrap`}>
      <Header
        name={group?.groupTitle}
        dispatch={dispatch}
        filters={group?.searchValue?.filters}
        group={group}
        page={group?.page}
        handleChangePage={handleChangePage}
        rowsPerPage={group?.rowsPerPage}
        count={Object.keys(group?.filteredData || {})?.length ?? 0}
        wsSocket={wsSocket}
        groupCollection={groupCollection}
        scannerLayout={scannerLayout}
        groups={groups}
        hideHeader={hideHeader}
        hideDateSelectorForGroups={hideDateSelectorForGroups}
        hideSearch={hideSearch}
        hidePagination={hidePagination}
        secondaryHeader={displaySecondaryTitle()}
        useSecondaryHeaderTitle={useSecondaryHeaderTitle}
        groupNumber={groupNumber}
        openReportModal={openReportModal}
        hasMainReport={hasMainReport}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        updatingDate={updatingDate}
        setUpdatingDate={setUpdatingDate}
        currentDate={currentDate}
        colorLegend={colorLegend}
        openLegendModal={openLegendModal}
      />
      {group ? (
        <div className="scanner">
          <div className="flex justify-end mx-auto w-full delay-info">
            {/* {(process.env?.REACT_APP_LIVE_DATA) ? (
              <p className="delay-info ml-auto mr-0 justify-end">
                <small>45 Minute Delay</small>
              </p>
            ) : null} */}
            {/* {!isTradingHours() ? (
              <p className="after-market-hours w-full text-right">
                <small>Data represents values from prior business day&apos;s close</small>
              </p>
            ) : null} */}
          </div>

          <ScannerTable
            tableData={group?.filteredData ?? {}}
            dataTypes={group?.dataTypes ?? []}
            orderBy={group?.orderBy}
            group={group}
            page={group?.page}
            multiScreen={multiScreen}
            order={group?.order}
            rowsPerPage={group?.rowsPerPage}
            dispatch={dispatch}
            handleChangePage={handleChangePage}
            getSymbolData={getSymbolData}
            openModal={openModal}
            openOptionsModal={openOptionsModal}
            showReportModal={showReportModal}
            openReportModal={openReportModal}
            optionsColumns={optionsColumns}
            optionsAllowed={optionsAllowed}
            hasReportPopout={hasReportPopout}
            openLockedModal={openLockedModal}
            alignColumns={alignColumns}
            allowExportCSV={allowExportCSV}
            trimNumbersFromSymbol={trimNumbersFromSymbol}
            reports={reports}
            symbolsWithReports={symbolsWithReports}
            lockedColumns={lockedColumns}
            customLockedColumns={customLockedColumns}
          />
        </div>
      ) : (
        <div className="scanner"> Loading Scanner... </div>
      )}
    </div>
  );
};

export default React.memo(Scanner, (prev, next) => {
  const dataSame = JSON.stringify(prev?.group?.filteredData) === JSON.stringify(next?.group?.filteredData);
  const orderBySame = prev?.group?.orderBy === next?.group?.orderBy;
  const orderSame = prev?.group?.order === next?.group?.order;
  const pageSame = prev?.group?.page === next?.group?.page;
  const rowsPerPageSame = prev?.group?.rowsPerPage === next?.group?.rowsPerPage;
  const trimNumbersFromSymbolSame = prev?.trimNumbersFromSymbol === next?.trimNumbersFromSymbol;
  const updatingDate = prev.updatingDate === next.updatingDate;
  const currentTabSame = prev.currentTab === next.currentTab;
  const inViewSame = prev.inView === next.inView;

  return (
    dataSame &&
    orderBySame &&
    orderSame &&
    pageSame &&
    rowsPerPageSame &&
    trimNumbersFromSymbolSame &&
    updatingDate &&
    currentTabSame &&
    inViewSame
  );
});
